import React, { useState } from "react";
import starIcon from "../../assets/images/star-icon.png";
import contact from "../../assets/images/contact.png";

import axios from "axios";

const ContactForm = () => {
  const [serverState, setServerState] = useState({
    submitting: false,
    status: null,
  });

  const handleServerResponse = (ok, msg, form) => {
    setServerState({
      submitting: false,
      status: { ok, msg },
    });
    if (ok) {
      form.reset();
    }
  };

  const handleOnSubmit = (e) => {
    e.preventDefault();
    const form = e.target;
    setServerState({ submitting: true });
    axios({
      method: "post",
      url: "https://getform.io/f/5f341657-358a-4fed-a084-07bce0b9070d",
      data: new FormData(form),
    })
      .then((r) => {
        handleServerResponse(true, "Thank you for filling the form!", form);
      })
      .catch((r) => {
        handleServerResponse(
          false,
          "Oops! the connection has been interrupted. Please try to submit again.",
          form
        );
      });
  };

  return (
    <section className="contact-area pb-5">
      <div className="container">
        {/* <div className="section-title">
                    <span className="sub-title">
                        <img src={starIcon} alt="contact" /> 
                        Get in Touch
                    </span>
                    <h2>Ready to Get Started?</h2>
                    <p>Your email address will not be published. Required fields are marked *</p>
                </div> */}

        <div className="row">
          <div className="col-lg-6 col-md-12">
            <h3 className="mt-3">Ready to Win?</h3>
            <h3 className="mb-3">Send Us A Message</h3>
            <p>
              If you think our ROI-focused approach to custom software and
              marketing makes sense for your business, get in touch with us
              today.
            </p>

            <h5 className="text-center pt-4">Or Give Us A Whatsapp Message</h5>
            <h5>
              <a
                href=" https://wa.me/9840109843?text=I'm%20interested%20in%20your%20car%20for%20sale"
                className="text-center d-block font-weight-bold text-blue"
                target="_blank"
              >
                +91 98401 09843
              </a>
            </h5>

            <iframe
              src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d15545.697130118071!2d80.2132067!3d13.0722752!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x0%3A0xb8fd49d083620c4!2sZESTECHNO!5e0!3m2!1sen!2sin!4v1606655592105!5m2!1sen!2sin"
              width="100%"
              height="350"
              frameBorder="0"
              allowFullScreen=""
              aria-hidden="false"
              tabIndex="0"
              className="d-block mt-5"
              title="Zestechno Map"
            ></iframe>
          </div>

          <div className="col-lg-6 col-md-12">
            <div className="contact-form">
              <form id="contactForm" onSubmit={handleOnSubmit}>
                <div className="row">
                  <div className="col-lg-6 d-none">
                    <div className="form-group">
                      <input
                        type="text"
                        name="Form Type"
                        className="form-control"
                        id="formtype"
                        value="Contact form"
                      />
                    </div>
                  </div>

                  <div className="col-lg-6 col-md-6">
                    <div className="form-group">
                      <input
                        type="text"
                        name="name"
                        className="form-control"
                        required
                        placeholder="Your name"
                      />
                    </div>
                  </div>

                  <div className="col-lg-6 col-md-6">
                    <div className="form-group">
                      <input
                        type="email"
                        name="email"
                        className="form-control"
                        required
                        placeholder="Your email address"
                      />
                    </div>
                  </div>

                  <div className="col-lg-6 col-md-6">
                    <div className="form-group">
                      <input
                        type="text"
                        name="phone_number"
                        className="form-control"
                        required
                        placeholder="Your phone number"
                      />
                    </div>
                  </div>

                  <div className="col-lg-6 col-md-6">
                    <div className="form-group">
                      <input
                        type="text"
                        name="company"
                        className="form-control"
                        required
                        placeholder="Your Company"
                      />
                    </div>
                  </div>

                  <div className="col-lg-12 col-md-12 selectBox">
                    <p>How Can We Help You ?</p>
                    <div className="form-group">
                      <input
                        className="d-inline w-auto mr-2"
                        type="checkbox"
                        name="seo"
                        id="seo"
                      />
                      <label className="form-check-label" htmlFor="seo">
                        SEO
                      </label>
                    </div>
                    <div className="form-group">
                      <input
                        className="d-inline w-auto mr-2"
                        type="checkbox"
                        name="paid"
                        id="paid"
                      />
                      <label className="form-check-label" htmlFor="paid">
                        Paid Search
                      </label>
                    </div>
                    <div className="form-group">
                      <input
                        className="d-inline w-auto mr-2"
                        type="checkbox"
                        name="lead"
                        id="lead"
                      />
                      <label className="form-check-label" htmlFor="lead">
                        Lead Generation
                      </label>
                    </div>
                    <div className="form-group">
                      <input
                        className="d-inline w-auto mr-2"
                        type="checkbox"
                        name="web"
                        id="web"
                      />
                      <label className="form-check-label" htmlFor="web">
                        Web Design
                      </label>
                    </div>
                    <div className="form-group">
                      <input
                        className="d-inline w-auto mr-2"
                        type="checkbox"
                        name="everything"
                        id="everything"
                      />
                      <label className="form-check-label" htmlFor="everything">
                        I Need Everything
                      </label>
                    </div>
                  </div>

                  <div className="col-lg-12 col-md-12 mt-3">
                    <div className="form-group">
                      <textarea
                        name="message"
                        className="form-control"
                        cols="30"
                        rows="6"
                        required
                        placeholder="Write your message..."
                      />
                    </div>
                  </div>

                  <div className="col-lg-12 col-md-12">
                    <button
                      type="submit"
                      className="default-btn"
                      disabled={serverState.submitting == true ? true : false}
                    >
                      <i className="flaticon-tick"></i>
                      {serverState.submitting == true
                        ? "Submitting"
                        : "Send Message"}
                    </button>
                  </div>

                  <div className="col-lg-12 col-md-12">
                    {serverState.status && (
                      <p
                        className={
                          !serverState.status.ok ? "errorMsg" : "successMsg"
                        }
                      >
                        {serverState.status.msg}
                      </p>
                    )}
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
      {/* <div className="mapLocation mt-5">
        <iframe
          src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d15545.697130118071!2d80.2132067!3d13.0722752!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x0%3A0xb8fd49d083620c4!2sZESTECHNO!5e0!3m2!1sen!2sin!4v1606655592105!5m2!1sen!2sin"
          width="100%"
          height="450"
          frameBorder="0"
          allowFullScreen=""
          aria-hidden="false"
          tabIndex="0"
          className="d-block"
          title="Zestechno Map"
        ></iframe>
      </div>
     */}
    </section>
  );
};

export default ContactForm;
